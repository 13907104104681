import styled from 'styled-components';

interface AlertProps {
  isCompareDataEqual: boolean;
  backgroundColor: string;
}

export const AlertBox = styled.div<AlertProps>`
  width: auto;
  height: 62px;
  .alert-class {
    > * {
      border-radius: 4px;
      position: relative;
      border-bottom: 2px solid #cf1919;
    }
    p {
      position: absolute;
      right: 0;
      border: none;
      color: #cf1919;
    }
  }
  ${(props) =>
    props.isCompareDataEqual
      ? ''
      : `
    input {
      background: ${props.backgroundColor};
      color: #fff;
    }
  `}
`;
