/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  ChangeEvent,
  useCallback,
  useMemo,
  useEffect,
  useRef,
  Ref,
  MutableRefObject,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import InputMask from 'react-input-mask';

import { AlertBox } from './styles';

type HTMLInputProps = Omit<React.HTMLAttributes<HTMLInputElement>, 'onChange'>;

interface FormProps extends HTMLInputProps {
  label: string;
  value: string | undefined;
  name?: string;
  isRequired?: boolean;
  minLength?: number;
  maxLength?: number;
  toUpperCase?: boolean;
  isUndefined?: boolean;
  isNull?: boolean;
  isEmpty?: boolean;
  setMask?:
    | 'cpf'
    | 'cnpj'
    | 'rg'
    | 'ie'
    | 'cep'
    | 'telefone'
    | 'celular'
    | 'cest'
    | 'numcc'
    | 'ncm';
  placeholder?: string;
  onChange(data: string, data1: boolean): any;
  setInvalid?: boolean;
  iniInicializado?: boolean;
  isDisabled?: boolean;
  autoFocus?: boolean;
  type?: 'email' | 'cpf' | 'password' | 'text';
  onKeyPress?: any;
  compareData?: [any, any, string];
  autoComplete?: string;
  refs?: MutableRefObject<any>;
}

/**
 * @param label Nome que será exibido na parte superior do componente
 * @function onChange Função que atualiza o valor do input
 * @returns Componente para validação de inputs
 */
// const InputText: React.FC<FormProps & onChangeProp> = (props) => {
const InputText: React.FC<FormProps> = forwardRef((props, refs) => {
  const {
    label,
    value = '',
    name,
    isRequired = false,
    onChange,
    maxLength = 50,
    minLength = 0,
    toUpperCase = false,
    setMask = null,
    isUndefined = false,
    isNull = null,
    isEmpty = null,
    placeholder = `Digite um valor para ${label}`,
    setInvalid = false,
    iniInicializado = false,
    isDisabled = false,
    type = undefined,
    onKeyPress,
    compareData = [undefined, undefined, '#FAFBFC'],
    autoComplete = 'on',
    ...rest
  } = props;
  // armazena se a validação foi iniciada
  const [inicializado, setInicializado] = useState(false);
  // armazena se o input passou na validação
  const [isInvalid, setIsInvalid] = useState(false);
  const regCpf = /^(([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2})|([0-9]{11}))$/;
  const regMail =
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const mask = useMemo(() => {
    return {
      cpf: '999.999.999-99',
      cnpj: '99.999.999/9999-99',
      rg: '99.999.999-*',
      ie: '999.999.999.999',
      cep: '99999-999',
      telefone: '(99) 9999-9999',
      celular: '(99) 99999-9999',
      numcc: '99999-*',
      cest: '99.999.99',
      ncm: '9999.99.99',
      none: '',
    };
  }, []);
  const [isCompareDataEqual, setIsCompareDataEqual] = useState(true);
  const [backgroundColor, setBackgroundColor] = useState(compareData[2]);
  const [cursor, setCursor] = useState<number | null>(null);
  const inputRef = useRef<any>();

  // Importante para manter a posição do cursor no input
  useEffect(() => {
    if (type !== 'email') {
      const input = inputRef.current;
      if (input) input.setSelectionRange(cursor, cursor);
    }
  }, [inputRef, cursor, value]);

  const validation = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let newValue = e.target.value;
      setCursor(e.target.selectionStart);
      if (setMask) {
        newValue = newValue.replace(/[^x-xX-X0-9]/g, '');
      }
      if (toUpperCase) {
        newValue = newValue.toUpperCase();
      }
      if (inicializado && isRequired) {
        if (isUndefined && newValue === undefined) {
          setIsInvalid(true);
          onChange(newValue, true);

          return;
        }
        if (isNull && newValue === null) {
          setIsInvalid(true);
          onChange(newValue, true);

          return;
        }
        if (isEmpty && newValue === '') {
          setIsInvalid(true);
          onChange(newValue, true);

          return;
        }
        if (isEmpty && newValue.trim() === '') {
          setIsInvalid(true);
          onChange(newValue, true);

          return;
        }
        if (
          setMask !== null &&
          newValue.length !==
            mask[setMask].replace(/[^a-zA-Z0-9*]/g, '').length &&
          (isEmpty || isNull || isUndefined)
        ) {
          setIsInvalid(true);
          onChange(newValue, true);

          return;
        }
      }
      if (inicializado) {
        if (
          type === 'email' &&
          newValue !== '' &&
          regMail.test(newValue) === false
        ) {
          setIsInvalid(true);
          onChange(newValue, true);

          return;
        }
      }

      if (inicializado) {
        if (
          type === 'cpf' &&
          newValue !== '' &&
          regCpf.test(newValue) === false
        ) {
          setIsInvalid(true);
          onChange(newValue, true);

          return;
        }
      }
      onChange(newValue, false);
      setIsInvalid(false);
      setInicializado(true);
    },
    [
      inicializado,
      isEmpty,
      isNull,
      isRequired,
      isUndefined,
      label,
      mask,
      onChange,
      setMask,
      toUpperCase,
    ],
  );

  useEffect(() => {
    if (compareData) {
      setIsCompareDataEqual(compareData[0] === compareData[1]);
      setBackgroundColor(compareData[2]);
    }
  }, [compareData]);

  // Efetuam a validação caso o input não seja altera e o botão submit seja clicado
  useEffect(() => {
    if (iniInicializado && isRequired) {
      setIsInvalid(setInvalid);
    }
  }, [iniInicializado, isRequired, setInvalid]);

  const addAlert = useCallback((): string => {
    // if (inicializado && isInvalid && setMask === null) {
    //   return 'alert-class';
    // }
    // // Input invalido com mascara
    // if (inicializado && isInvalid && setMask !== null) {
    //   return 'alert-class';
    // }
    // // Input com mascara
    // if (inicializado && isInvalid === false && setMask !== null) {
    //   return '';
    // }
    // if (isInvalid === false && setMask !== null) {
    //   return '';
    // }
    // return '';

    if (iniInicializado && isInvalid && setMask === null) {
      return 'alert-class';
    }
    // Input invalido com mascara
    if (iniInicializado && isInvalid && setMask !== null) {
      return 'alert-class';
    }
    // Input com mascara
    if (iniInicializado && isInvalid === false && setMask !== null) {
      return '';
    }
    if (isInvalid === false && setMask !== null) {
      return '';
    }
    return '';
  }, [isInvalid, iniInicializado]);

  useImperativeHandle(props.refs, () => ({
    focus: () => (inputRef.current ? inputRef.current.focus() : null),
  }));

  return (
    <AlertBox
      isCompareDataEqual={isCompareDataEqual}
      backgroundColor={backgroundColor}
    >
      <div className={addAlert()}>
        {setMask ? (
          <Field label={label} name={name || label}>
            {({ fieldProps }) => (
              <InputMask
                {...fieldProps}
                mask={mask[setMask]}
                value={value}
                disabled={isDisabled}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  validation(e);
                }}
                onClick={(e: any) => {
                  if (e.target.value.replace(/[^\d]/g, '') === '') {
                    inputRef.current.setSelectionRange(0, 0);
                  }
                }}
                onFocus={(e: any) => {
                  if (e.target.value.replace(/[^\d]/g, '') === '') {
                    inputRef.current.setSelectionRange(0, 0);
                  }
                }}
              >
                {(inputProps: any) => (
                  <Textfield
                    {...inputProps}
                    type="text"
                    minLength={minLength}
                    maxLength={maxLength}
                    // autoFocus={autoFocus}
                    placeholder={placeholder}
                    value={value}
                    isDisabled={isDisabled}
                    onKeyPress={onKeyPress}
                    ref={refs || inputRef}
                    {...(!isCompareDataEqual
                      ? {
                          title: `
                          ERP: ${compareData && compareData[0]}\n\nXML: ${
                            compareData && compareData[1]
                          }`,
                        }
                      : {})}
                    {...rest}
                  />
                )}
              </InputMask>
            )}
          </Field>
        ) : (
          <Field label={label} name={name || label}>
            {({ fieldProps }) => (
              <>
                <Textfield
                  {...fieldProps}
                  type={type || 'text'}
                  minLength={minLength}
                  maxLength={maxLength}
                  autoComplete={autoComplete}
                  // autoFocus={autoFocus}
                  placeholder={placeholder}
                  value={value}
                  isDisabled={isDisabled}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    validation(e);
                  }}
                  onKeyPress={onKeyPress}
                  ref={refs || inputRef}
                  {...(!isCompareDataEqual
                    ? {
                        title: `
                        ERP: ${compareData && compareData[0]}\n\nXML: ${
                          compareData && compareData[1]
                        }`,
                      }
                    : {})}
                  {...rest}
                />
              </>
            )}
          </Field>
        )}
      </div>
    </AlertBox>
  );
});

export default InputText;
