import React, { useEffect, useState, ChangeEvent, useCallback } from 'react';
import { Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import Button from '@atlaskit/button';

import { AlertBox } from './styles';

interface FormProps {
  label: string;
  invalidLabel?: string;
  value: string | number | undefined;
  isRequired?: boolean;
  min?: number;
  max?: number;
  isUndefined?: boolean;
  isNull?: boolean;
  isEmpty?: boolean;
  placeholder?: string;
  onChange(data: number | string, data1: boolean): any;
  setInvalid?: boolean;
  iniInicializado?: boolean;
  isDisabled?: boolean;
  autoFocus?: boolean;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
}

/**
 * @param label Nome que será exibido na parte superior do componente
 * @function onChange Função que atualiza o valor do input
 * @returns Componente para validação de inputs
 */
const InputPercent: React.FC<FormProps> = (props) => {
  const {
    label,
    value,
    isRequired = false,
    onChange,
    min = 0,
    max = 99999,
    isUndefined = false,
    isNull = null,
    isEmpty = null,
    placeholder = `Digite um valor para ${label}`,
    setInvalid = false,
    iniInicializado = false,
    isDisabled = false,
    autoFocus = false,
    onBlur,
  } = props;
  // armazena se a validação foi iniciada
  const [inicializado, setInicializado] = useState(false);
  // armazena se o input passou na validação
  const [isInvalid, setIsInvalid] = useState(false);

  const validation = useCallback(
    (_newValue: string) => {
      const setValue = Number.parseFloat(_newValue);

      if (setValue > max) {
        // setIsInvalid(true);
        // onChange(_newValue, true);
        return;
      }

      if (inicializado && isRequired) {
        if (isUndefined && _newValue === undefined) {
          // setIsInvalid(true);
          onChange(_newValue, true);
          return;
        }
        if (isNull && _newValue === null) {
          // setIsInvalid(true);
          // onChange(_newValue, true);
          return;
        }
        if (isEmpty && _newValue === '') {
          // setIsInvalid(true);
          onChange(_newValue, true);
          return;
        }
        if (isEmpty && _newValue.trim() === '') {
          // setIsInvalid(true);
          onChange(_newValue, true);
          return;
        }

        if (setValue > max) {
          // setIsInvalid(true);
          onChange(_newValue, true);
          return;
        }
      }
      if (inicializado) {
        if (setValue < min) {
          // setIsInvalid(true);
          onChange(_newValue, true);
          return;
        }
        if (setValue > max) {
          setIsInvalid(true);
          onChange(_newValue, true);
          return;
        }
      }
      onChange(_newValue, false);
      setIsInvalid(false);
      setInicializado(true);
    },
    [
      inicializado,
      isEmpty,
      isNull,
      isRequired,
      isUndefined,
      max,
      min,
      onChange,
    ],
  );

  // Efetuam a validação caso o input não seja alterado e o botão submit seja clicado
  useEffect(() => {
    if (inicializado && isRequired) {
      setIsInvalid(setInvalid);
    }
  }, [inicializado, isRequired, setInvalid]);

  useEffect(() => {
    setInicializado(iniInicializado);
  }, [iniInicializado]);

  useEffect(() => {
    addAlert();
  }, [setInvalid]);

  const addAlert = useCallback((): string => {
    if (setInvalid) {
      return 'alert-class';
    }
    return '';
  }, [setInvalid]);

  return (
    <AlertBox>
      <div className={addAlert()}>
        <Field label={label} name={label}>
          {({ fieldProps }) => (
            <>
              <Textfield
                {...fieldProps}
                type="number"
                min={min}
                max={max}
                step="1"
                autoFocus={autoFocus}
                value={value}
                placeholder={placeholder}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  validation(e.target.value);
                }}
                onBlur={onBlur}
                style={{ textAlign: 'right', paddingRight: '40px' }}
              />
              <Button
                type="button"
                className="inputButton"
                style={{
                  float: 'right',
                  marginTop: '-40px',
                  height: '40px',
                  padding: '5px',
                  alignItems: 'center',
                  cursor: 'default',
                }}
                tabIndex={-1}
              >
                Dias
              </Button>
            </>
          )}
        </Field>
      </div>
    </AlertBox>
  );
};

export default InputPercent;
