import styled from 'styled-components';

interface IAlertBox {
  inLine: boolean;
  fontSize: string;
  textPaddingLeft?: string;
  textPaddingRight?: string;
}

export const AlertBox = styled.div<IAlertBox>`
  position: relative;
  height: 100%;
  transition: 0.2s;

  .react-select__placeholder {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 250px;
  }

  .react-select__single-value--is-disabled {
    color: #a5adba;
  }

  .alert-class {
    > * {
      border-radius: 4px;
      position: relative;
      transition: 0.2s;
    }
    p {
      position: absolute;
      right: 0;
      border: none;
      color: #cf1919;
      transition: 0.2s;
    }
    .react-select__control {
      border-bottom: 2px solid #cf1919;
    }
    .react-select__control--is-focused {
      border-top: 2px solid;
      border-left: 2px solid;
      border-right: 2px solid;
      border-color: #cf1919;
      border-bottom: 2px solid #cf1919;
    }
    .sc-iqAclL {
      color: #cf1919 !important;
    }
    .lflqij {
      color: #cf1919 !important;
    }
  }
  @media only screen and (min-width: 992px) {
    .select--field {
      position: relative;
      bottom: 0px;
      /* position: absolute;
      bottom: 8px; */
      .single-select {
        flex: 1;
      }
      ${(props) =>
        props.inLine === true
          ? `
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          width: 100% !important;
          .label--text {
            margin-right: 10px;
            margin-top: 2px;
            font-size: ${props.fontSize};
            font-style: inherit;
            line-height: 1.3333333333333333;
            color: #6b778c;
            font-weight: 600;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
              'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
          }
          `
          : ``};
    }
    .react-select__value-container {
      ${(props) =>
        props.textPaddingLeft
          ? `
      padding-left: ${props.textPaddingLeft};
    `
          : ''};
      ${(props) =>
        props.textPaddingRight
          ? `
      padding-right: ${props.textPaddingRight};
    `
          : ''};
    }
  }
`;
