import { Field } from '@atlaskit/form';
import Select from '@atlaskit/select';
import React, { Ref, useCallback, useEffect, useState } from 'react';

import { AlertBox } from './styles';

interface Options {
  label: string | undefined;
  value: number | string | undefined;
}

type HTMLInputProps = Omit<React.HTMLAttributes<HTMLInputElement>, 'onChange'>;

interface FormProps extends HTMLInputProps {
  label: string;
  value: Options | undefined | null;
  isRequired?: boolean;
  isDisabled?: boolean;
  options: Options[];
  onChange(data: Options, data1: boolean): any;
  setInvalid?: boolean;
  iniInicializado?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  inLine?: boolean;
  noOptionsMessage?: string;
  fontSize?: string;
  textPaddingLeft?: string;
  textPaddingRight?: string;
  name?: string;
  refs?: Ref<any>;
  openMenuOnFocus?: boolean;
  menuPosition?: any;
  menuPlacement?: any;
  minMenuHeight?: number;
}

/**
 * @param label Nome que será exibido na parte superior do componente
 * @function onChange Função que atualiza o valor do input
 * @returns Componente para validação de inputs
 */
const InputSelect: React.FC<FormProps> = (props) => {
  const {
    id,
    label,
    value,
    options,
    onChange,
    isRequired = false,
    isDisabled = false,
    setInvalid = false,
    iniInicializado = false,
    placeholder = undefined,
    noOptionsMessage = 'Nenhum registro encontrado',
    autoFocus = false,
    inLine = false,
    fontSize = '0.8571428571428571em',
    textPaddingLeft,
    textPaddingRight,
    name,
    refs,
    openMenuOnFocus = true,
    menuPosition = 'absolute',
    menuPlacement = 'bottom',
    minMenuHeight = 200,
    ...rest
  } = props;
  // const { options } = props;
  // armazena se o input passou na validação
  const [isInvalid, setIsInvalid] = useState(false);
  // armazena se a validação foi iniciada
  const [inicializado, setInicializado] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  // const selectRef = useRef<any>(null);

  const validation = useCallback(
    (e: any) => {
      const newValue: any = e;
      if (isRequired) {
        if (newValue.label === undefined || newValue.value === undefined) {
          setIsInvalid(true);
          onChange(newValue, true);
          return;
        }
      }
      onChange(newValue, false);
      setIsInvalid(false);
      setInicializado(false);
    },
    [isRequired, onChange],
  );

  useEffect(() => {
    if (inicializado && isRequired) {
      setIsInvalid(setInvalid);
    }
  }, [inicializado, isRequired, setInvalid]);
  useEffect(() => {
    setInicializado(iniInicializado);
  }, [iniInicializado]);

  const addAlert = useCallback((): string => {
    if (inicializado && isInvalid) {
      return 'alert-class';
    }
    return '';
  }, [inicializado, isInvalid]);

  const customStyles = {
    control: (styles: any) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? 'var(--ds-background-disabled,#FAFBFC)'
          : 'var(--ds-background-subtleBorderedNeutral-resting,#FAFBFC)',
        borderColor: isDisabled
          ? 'var(--ds-background-disabled,#FAFBFC)'
          : 'var(--ds-border-neutral,#DFE1E6)',
      };
    },
    singleValue: (provided: any, state: any) => {
      return {
        ...provided,
        color: value?.value ? '#172B4D' : 'var(--ds-text-lowEmphasis,#373a3e)',
      };
    },
  };

  return (
    <AlertBox
      inLine={inLine}
      fontSize={fontSize}
      title={!isOpen ? value?.label : ''}
      textPaddingLeft={textPaddingLeft}
      textPaddingRight={textPaddingRight}
    >
      <div className={addAlert()}>
        <Field
          name={name ?? 'buscar_por'}
          defaultValue=""
          label={inLine === false ? label : ''}
        >
          {() => (
            <div className="select--field">
              {inLine === true && (
                <label className="label--text" htmlFor="select">
                  {label}
                </label>
              )}
              <Select
                inputId={id}
                placeholder={placeholder ?? 'Selecione...'}
                title="Title on the select"
                options={options}
                name={name}
                className="single-select"
                classNamePrefix="react-select"
                onChange={validation}
                value={value === undefined ? [] : value}
                onMenuOpen={() => setIsOpen(true)}
                onMenuClose={() => setIsOpen(false)}
                menuPosition={menuPosition}
                menuPlacement={menuPlacement}
                minMenuHeight={minMenuHeight}
                openMenuOnFocus={openMenuOnFocus}
                noOptionsMessage={() => String(noOptionsMessage)}
                loadingMessage={() => 'Buscando...'}
                isDisabled={isDisabled}
                autoFocus={autoFocus}
                styles={customStyles}
                ref={refs}
              />
            </div>
          )}
        </Field>
      </div>
    </AlertBox>
  );
};

export default InputSelect;
